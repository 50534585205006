<template>
  <div class="plan-code-activate">
    <Loader v-if="isLoading" />
    <div v-else>
      <vs-row>
        <vs-col vs-w="2" />
        <vs-col vs-w="8">
          <vs-card v-if="canActivate">
            <div slot="header">
              <h3>{{ $l.get("plan-code-activate.header") }}</h3>
            </div>
            <vs-input
              class="mt-4"
              placeholder="MXHU2..."
              v-model="code"
              size="large"
              autofocus="true"
              :danger="isCodeDanger"
              :danger-text="$l.get('plan-code-activate.invalid-code')"
              :warning="isCodeWarning"
              :warning-text="$l.get('plan-code-activate.incomplete-code')"
              :success="isCodeSuccess"
              :success-text="$l.get('plan-code-activate.valid-code')"
            />
            <vs-button
              class="mt-4"
              :disabled="!isCodeSuccess"
              type="filled"
              @click="onActivateClick"
              >{{ $l.get("plan-code-activate.activate") }}</vs-button
            >
          </vs-card>
          <div class="mt-4 mb-4" v-else style="text-align: center">
            <feather-icon
              icon="CheckIcon"
              class="animated infinite pulse"
              svgClasses="text-success h-24 w-24"
            />
            <h3 style="color: #999999" class="mt-4">
              {{ $l.get("plan-code-activate.already-activated") }}
            </h3>
          </div>
        </vs-col>
        <vs-col vs-w="2" />
      </vs-row>
    </div>
  </div>
</template>

<script>
import SubmitPlanCodeInteractor from "@/business/plan-code-activate/submit-plan-code";
import InitPlanCodeActivateScreenInteractor from "@/business/plan-code-activate/init-plan-code-activate-screen";
import PlanCodeActivateScreenController from "@/adapters/controllers/screen-plan-code-activate";
import { PlanCode } from "@/domain/plans/models";

import Loader from "@/application/components/Loader.vue";

export default {
  screen_name: "plan-code-activate",
  components: {
    Loader
  },
  data() {
    return {
      controller: null,
      interactors: {
        submitPlanCode: null,
        initPlanCodeActivateScreen: null
      },
      code: "",
      isCodeDanger: false,
      isCodeWarning: false,
      isCodeSuccess: false,
      isLoading: false,
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(PlanCodeActivateScreenController);

    //{ INTERACTORS
    this.interactors.initPlanCodeActivateScreen = this.$injector.get(
      InitPlanCodeActivateScreenInteractor
    );
    this.interactors.submitPlanCode = this.$injector.get(
      SubmitPlanCodeInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.setAsCodeNotFound = this.setAsCodeNotFound;
  },
  mounted() {
    this.interactors.initPlanCodeActivateScreen.handle();
  },
  watch: {
    code() {
      this.code = this.code.toUpperCase();
      this.isCodeDanger = false;
      this.isCodeWarning = false;
      this.isCodeSuccess = false;
      if (this.code.length == 0) {
        return;
      } else if (this.code.length > 8) {
        this.code = this.code.substring(0, 8);
      } else if (this.code.length < 8) {
        this.isCodeWarning = true;
      } else if (this.code.length == 8) {
        const isValid = PlanCode.validate(this.code);
        this.isCodeDanger = !isValid;
        this.isCodeSuccess = isValid;
      }
    }
  },
  computed: {
    canActivate() {
      return this.data && !this.data.session.user.plan.plan.isTransformer;
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    setAsCodeNotFound() {
      this.$vs.dialog({
        color: "danger",
        title: this.$l.get("plan-code-activate.code-not-found"),
        text: this.$l.get("plan-code-activate.code-not-found-info"),
        acceptText: this.$l.get("dictionary.close")
      });
      this.code = "";
    },
    onActivateClick() {
      this.interactors.submitPlanCode.handle(this.code);
    }
  }
};
</script>

<style lang="scss">
.plan-code-activate .vs-input {
  width: 100% !important;
}
.plan-code-activate .vs-button {
  width: 100% !important;
}
</style>
