import { Entity } from "@/commons/models";

export class Plan extends Entity {
  name: string;
  source_type: number;
  payment_type: number;
  isPlatinum: boolean = false;

  constructor(
    name: string,
    source_type: number,
    payment_type: number,
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
    this.name = name;
    this.source_type = source_type;
    this.payment_type = payment_type;
  }

  canDownloadReport(): boolean {
    return this.payment_type != 1;
  }
}

export class UserPlan extends Entity {
  plan: Plan;

  constructor(
    plan: Plan,
    id?: string,
    createdAt?: Date,
    modifiedAt?: Date,
    disabledAt?: Date
  ) {
    super(id, createdAt, modifiedAt, disabledAt);
    this.plan = plan;
  }

  canDownloadReport(): boolean {
    return this.plan.canDownloadReport();
  }
}

export class PlanCode {
  static validate(code: string): boolean {
    if (code.length != 8) return false;
    const chars = "NTZJ4UFCIRH3EO078BD1YQALKM29S6VX5WPG";
    let sum = 0;
    for (let i = 0; i < 7; i++) {
      sum += code.charCodeAt(i) * (i + 1);
    }
    return code.charCodeAt(7) == chars.charCodeAt(sum % chars.length);
  }
}
